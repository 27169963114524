.FindMyBrickScreen {
  min-height: 600px;
  max-width: 800px;
  width: 100%;
  min-width: 300px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: space-around;

  input {
    max-width: 600px;
    min-width: 150px;
    width: 80%;
  }

  h1.error {
    background: darkblue;
    border-radius: 20px;
    padding: 30px;
    font-weight: lighter;
  }

  p {
    display: block;
    line-height: 40px;
  }

  .specialButton {
    padding: 10px;
    margin-bottom: 10px;
  }

  .button-list {
    display: grid;
    grid-template-columns: 300px 300px;
    grid-template-rows: 100px 100px;
    gap: 1px 1px;
    grid-template-areas: ". ." ". .";
    align-items: center;
    justify-items: center;
  }

  .previous_search {
    background: darkblue;
    padding: 20px;
    border-radius: 20px;
    font-size: 25px;
    color: white;
  }

}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.specialButtonArea {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100px;

  * {
    margin: 0px 10px;
  }
}

.selectionDialog {
  margin: 20px 0px;
  width: 100%;
  min-width: 200px;
  max-width: 1440px;
  padding: 20px;
  //height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  color: gray;
  text-transform: uppercase;

  .state-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    //max-width: 80%;


    .stateButton {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 50px;
      width: 200px;
      margin: 10px;
      color: black;
      border-radius: 20px;
      border: 3px solid transparent;
      font-weight: bold;
      padding: 40px;
      background: #4c1517;
      cursor: pointer;

      h2 {
        font-size: 20px;
        font-weight: lighter;
        color: white;
      }

      img {
        width: 100%;
        background-size: contain;
        object-fit: contain;
      }
    }

    .areaButton {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 150px;
      width: 200px;
      margin: 10px;
      color: black;
      border-radius: 20px;
      border: 3px solid transparent;
      font-weight: bold;
      padding: 40px;
      cursor: pointer;


      &:hover {
        background: #4c1517;
      }

      h2 {
        font-size: 20px;
        font-weight: lighter;
        margin: 0px 0px 0px 0px;
        color: white;
        position: relative;
        bottom: 5px;
        font-weight: bold;
      }

      img {
        width: 100%;
        height: 150px;
        background-size: contain;
        object-fit: contain;
      }
    }

    .originButton {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 150px;
      width: 200px;
      margin: 10px;
      color: black;
      border-radius: 20px;
      border: 3px solid white;
      font-weight: bold;
      padding: 40px;
      cursor: pointer;


      &:hover {
        background: #4c1517;
      }

      h2 {
        font-size: 20px;
        font-weight: lighter;
        margin: 0px 0px 0px 0px;
        color: white;
        position: relative;
        bottom: 5px;
        font-weight: bold;
      }

      img {
        width: 100%;
        height: 150px;
        background-size: contain;
        object-fit: contain;
      }
    }

  }
}

.paginationPanel {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  width: 800px;

  .button-container {

  }

  button {
    width: 250px;
    font-size: 30px;
    text-transform: uppercase;
    font-weight: lighter;
    padding: 8px;
    height: 90px;
  }

  h4 {
    font-size: 30px;
    text-transform: uppercase;
    color: white;
    font-weight: lighter;
  }
}

.brink-list {
  display: flex;
  flex-direction: column;

}

.brinkLink {
  border-radius: 10px;
  border: 2px solid #444444;
  height: 100px;
  margin: 20px 0px;

  background: white;
  color: gray;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 100px;
  display: grid;
  align-items: center;
  cursor: pointer;

  &:hover {
    filter: hue-rotate(220deg);
  }

  .brickNumber {
    width: 200px;
    font-size: 30px;
  }

  .brickDescription {
    text-align: center;
    line-height: 30px;
    font-size: 20px;

    p {
      margin: 0;
      color: #4c1517;
      line-height: 30px;
      text-align: center;
      width: 400px;

    }
  }

  .donor {
    width: 200px;
    font-size: 20px;
  }
}

.person-screen-details {
  width: 700px;
  h4 {
    color:white;
    font-size: 35px;
    text-align: left;
  }
  h2 {
    color:white;
    font-size: 20px;
    text-align: left;
  }
}

.honoree-list {
  flex-direction: row;
  flex-wrap: wrap;
  width: 800px;
  display: flex;
}

.honoree-item {
  color:white;
  text-align: left;
  width: 350px;
  font-size: 20px;
  margin-top: 10px;
  cursor:pointer;
}

.honoree-item:hover {
  text-decoration: underline;
}

.peopleLink {
  border-radius: 10px;
  border: 2px solid #444444;
  height: 100px;
  margin: 20px 0px;

  background: white;
  color: gray;
  grid-template-columns: auto auto;
  grid-template-rows: 100px;
  display: grid;
  align-items: center;
  cursor: pointer;

  &:hover {
    filter: hue-rotate(220deg);
  }

  .brickNumber {
    width: 200px;
    font-size: 30px;
  }

  .peopleName {
    text-align: center;
    line-height: 30px;
    font-size: 20px;
    margin: 0 auto;

    p {
      margin: 0;
      color: #4c1517;
      line-height: 30px;
      text-align: center;
      width: 400px;
    }
  }

  .peopleLocation {
    margin: 0 auto;
    width: 200px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 700px) {
  .selectionDialog {
    width: 300px;
  }
  .specialButtonArea {
    display: flex;
    flex-direction: column;
    width: 300px;
    border:1px solid red;
  }
  .FindMyBrickScreen {
    width: 300px;
  }
  .brick-list {
    width: 300px;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  .brinkLink {
    width: 300px;
    height: auto;
    grid-template-columns: 300px;
    grid-template-rows: auto 1fr auto;
    .brickDescription p {
      width: 300px;
    }
    .brickNumber {
      margin: 20px auto;
      text-align: center;
    }
    .donor {
      margin: 20px auto;
      text-align: center;
    }
  }
}
