.Footer {
  min-height: 130px;
  margin: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  > * {
    margin-top: 20px !important;
  }
}

@media only screen and (height: 1920px) and (width: 1080px) {
  .Footer {
    height: 130px;
    background: url(../images/design/MHBF-footer-desktopwidth-final.png);
    background-size: cover;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: inherit;
    bottom:0;
    left:0;
    margin: 0 auto;
    width:100%;
    > * {
      margin-top: 0 !important;
    }
  }
}

button.footer-button {
  text-align: center;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: center;
  justify-items: center;
  border-radius: 20px;
  background:#fdfdfd;
  border: 2px solid #892527;
  margin:0px 10px;
  color: #892527;
  height: 80px;
  width: 200px;
  .img {
    height: 50px;
    width: 50px;
    background-position: center center;
    background-size: contain;
  }
  .caption {
    padding-left:10px;
    font-size: 20pt;
  }
}

@media only screen and (max-width: 700px) {
  button.print-button {
    display: none !important;
  }
  .footer-button {
    width: 50px !important;
    height: 50px !important;
    .img {
      width: 30px !important;
      height: 30px !important;
    }
    .caption {
      display: none;
    }
  }
  .Footer {
    height: 50px !important;
    background: url(../images/design/MHBF-footer-desktopwidth-final.png);
    background-size: cover;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: inherit;
    bottom:0;
    left:0;
    margin: 0 auto;
    width:100%;
    > * {
      margin-top: 0 !important;
    }
  }
}
