.BrickLocationScreen {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto 1fr;
  max-width: 1080px;
  margin: 25px auto 0 auto;

  .debug {
    font-size: 12px;
  }

  .locationDescription {
    height: 200px;
    background:white;
    border-bottom:1px solid #eb4511;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    font-size: 20px;
    .locationDescription1 {
      width: 900px;
      line-height: 30px;
    }

  }

  .Communication {
    border: 3px solid red;
    width: 600px;
    min-height: 600px;
    background:white;
    margin:0 auto;
    padding: 50px;
    border-radius: 10px;
    h1  {
      text-align:left;
    }
    p {
      text-align: justify;
      font-size: 30px;
      color:black;
      width: 100%;
    }
  }

  .brickInfo {
    height: 100px;
    margin:0px;
    background: white;
    width: 100%;
    background: url(../images/brick.svg) no-repeat;
    background-size: cover;
    color: white;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: 100px;
    display: grid;
    align-items: center;
    cursor: pointer;
    font-weight: bold;

    &:hover {
      filter: hue-rotate(220deg);
    }

    .brickNumber {
      width: 300px;
      font-size: 30px;
    }

    .brickDescription {
      text-align: center;
      line-height: 30px;
      font-size: 20px;
      p {
        margin: 0;
      }
    }

    .donor {
      width: 300px;
    }
  }

  .map {
    max-height: 700px;
  }

  .steps {
    width:100%;
    background: white;
  }

  img.barcode {
    position:fixed;
    top: 25px;
    right: 40px;
    width: 100px;
    height: 100px;
    border-radius: 10px;
  }


  .brickPlaque {
    color:black;
    text-align: center;
    min-height: 250px;
    max-height: 300px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border:1px solid gray;
    font-size: 20px;
    width:500px;
    margin:10px auto 200px;
    background-color: #eb4511;
    background-image: linear-gradient(315deg, #b96652 0%, #b96652 74%);
    font-family: "Times New Roman";
  }

  .stepBlock {
    display: grid;
    grid-template-columns: 100px 200px 1fr;
    grid-template-rows: 1fr;
    gap: 1px 1px;
    border-radius: 20px;
    width:900px;
    margin:10px auto;
    height: 180px;
    justify-items: center;
    align-items: center;
    overflow: hidden;
    .false-stepNumber {
      width: 50px;
      height: 50px;
    }
    .stepNumber {
      border-radius: 200px;
      width: 50px;
      height: 50px;
      background: #ff4b57;
      line-height: 50px;
      border: 3px solid red;
      color:white;
      font-weight: bold;
      box-shadow: 1px 1px 195px -72px rgba(255, 132, 89, 1);
    }

    &.Final {
      grid-template-columns: 100px 1fr;
      .stepNumber {
        background: #308c1f;
        border: 3px solid #12590e;
        box-shadow: 1px 1px 195px -72px rgba(255, 132, 89, 1);
      }
      .stepDescription {
        text-align: justify;
      }
    }

    .stepImage {
      width: 200px;
      height: 180px;
      border-radius: 10px;
      img, object {
        object-fit: cover;
        height: 150px;
        width:150px;
        border-radius: 15px;
        margin-top:20px;
      }
      .defaultImage {
        border:1px solid lightgray;
        background-color: #ead8c2;
        object-fit: contain;
        border-radius: 5px;
      }
    }
    .stepDescription {
      text-align: justify;
      padding:10px;
      width:100%;
      h2 {
        font-weight: lighter;
      }
      p {
        font-size: 15px;
        color:black;
      }
    }
  }

}

@media only screen and (max-width: 700px) {
  .BrickLocationScreen {
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
    .locationDescription {
      margin: 0 auto;
      padding: 0;
      .locationDescription1 {
        width: 90%;
      }
    }
    .BrickLocationScreen .map {
      height: auto;
    }
    .brickInfo {
      height: auto;
      grid-template-rows: auto 1fr auto;
      grid-template-columns: 1fr;
      .brickNumber {
        margin: 20px auto;
        padding-bottom:10px;
        border-bottom: 1px solid white;
      }
      .brickDescription {
        margin: 20px auto;
        padding-bottom:10px;
      }
      .donor {
        margin: 20px auto;
        padding-top:10px;
        border-top: 1px solid white;
      }
    }
    .steps {

    }
    .stepBlock {
      width: 300px;
      height:auto;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 100px 200px 1fr;
      .stepDescription {
        text-align: center;
      }
    }
  }

}
