@media (prefers-reduced-motion: no-preference) {
  .EagleIcon {
    position: relative;
    height: 100%;
    width: 100%;
    min-height: 200px;
    min-width: 200px;
    max-height: 400px;
    max-width: 400px;
    background: url(../images/design/MHS-logo-white.png) no-repeat center center;
    margin: 0 auto;
    background-size: contain;
  }
}
