.LoadingIcon {
    position: relative;
    height: 250px;
    width: 250px;
    display: flex;
    margin: 0 auto;

    .Animation {
      animation: Vector-spin infinite 1s linear;
      height: 250px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
