@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
  background: #5f1e1e url(/images/design/background.png) no-repeat;
  background-size: cover;
  font-family: "Roboto", sans-serif;
  height: 100vh;
}

root {
  margin: 0 auto;
}

* {
  font-family: 'Roboto', sans-serif;
}

footer {
  height: 150px;
  background: navy;
}

header {
  height: 150px;
  background: url(/images/design/MHBF-header-rounded-withredstripe-final.png) no-repeat center top;
  background-size: contain;
}

h1, p {
  color: white;
}

h1 {
  text-transform: uppercase;
  font-size: 20pt;
}

.App {
  text-align: center;
  display: grid;
  grid-template-rows: auto 1fr auto;
  align-items: center;
  border-radius: 0px 0px 15px 15px;
  height: 100vh;
  overflow: scroll;
}

.footer-space {
  height: 200px;
}

@media print {
  .no-print, .no-print * {
    display: none !important;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.BrickBackground {
  background: url(./images/brick.svg) no-repeat center center;
  height: 15px;
}

.Screen:only-child {
  width: 100%;
}

button {
  width: 300px;
  cursor: pointer;
  outline: none;
  font-weight: bold;
  border-radius: 25px;
  background: rgba(51, 4, 2, 0.7);
  color: #f6f4ed;
  font-size: 18pt;
  text-transform: uppercase;
  border: none;
  height: 100px;
}

input {
  height: 100px;
  border-radius: 18px;
  outline: none;
  width: 600px;
  font-size: 25px;
  padding: 0px 30px;
  margin: 20px;
  color: #330402;
  background: #f6f4ed;
  text-align: center;
  border: 3px solid #000;
  text-transform: uppercase;
}

input::placeholder {
  color: #330402;
}

@media print {
  .brickDescription {
    p {
      color: black !important;
    }
  }
  .brickInfo {
    color: black !important;
  }
  * {
    overflow: visible !important;
  }
  @page {
    size: A4;
  }
  body {
    background:white;
  }
  .Footer {
    display: none;
  }
  .footer-space {
    display: none;
  }
  svg {
    display: block;
  }
  img.barcode {
    display: none;
  }
  html {
    max-width: 1000px;
    height: auto;
    background: white;
  }
  .App {
    max-width: 1000px;
  }
  .Kernel {
    max-width: 1000px;
  }
  .brickInfo {
    max-width: 1000px !important;
  }
  header {
    display: none;
  }
  .locationDescription {
    border: 1px solid white !important;
  }
  .map {
    max-width: 1000px !important;
    background: white !important;
    page-break-after: always;
  }
  svg {
    max-width: 1000px !important;
  }
  .steps {
    max-width: 1000px !important;
      .stepBlock {
        page-break-before: auto !important;
        page-break-after: auto !important;
        page-break-inside: avoid !important;
      }

  }
}

@media only screen and (max-width: 700px) {
  img.barcode {
    display: none;
  }
  header {
    height: 100px;
    background-size: 200%;
  }
}
