.PaginationList {

  .honoree-list {
    flex-direction: row;
    flex-wrap: wrap;
    width: 800px;
    display: flex;
  }

  .honoree-item {
    color:white;
    text-align: left;
    width: 350px;
    font-size: 20px;
    margin-top: 10px;
    cursor:pointer;
  }

  .honoree-item:hover {
    text-decoration: underline;
  }

  button.buttonSmall {
    font-size: 18px;
    margin:50px 30px;
    width: 150px;
    height: 60px;
    padding:0px 10px;
  }
}

