.LandmarkDirectoryScreen {
  max-width: 900px;
  margin: 0 auto;
  flex-direction: column;
  justify-items: center;

  .selectedScreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #4c1517;
    opacity: 0.9;
  }

  .selectedLandmark {
    overflow:scroll;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;

    img, object {
      max-width: 600px;
      max-height: 600px;
      grid-row: 1;
      width: 350px;
      height: 250px;
      object-fit: cover;
      border-radius: 12px;
    }

    .defaultImage {
      border: 1px solid lightgray;
      background-color: #ead8c2;
      object-fit: contain;
      border-radius: 12px;
    }

    button {
      margin-top: 30px;
    }

    p {
      width: 400px;
      margin: 0 auto;
      font-size: 28px;
      text-align: justify;
      margin-top: 30px;
    }
  }


  .landmark-list {

    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: space-around;

    .landmark {
      height: 350px;
      width: 350px;
      margin: 10px;
      cursor: pointer;

      border-radius: 18px;
      border: 5px solid #4c1517;
      background: #4c1517;

      display: grid;
      grid-template-rows: 250px 100px;

      &:hover {
        background: #f2f2f2;
        border: 5px solid #fff;

        h1 {
          color: #4c1517;
        }
      }

      h4 {
        position: absolute;
        background: red;
        color: #fff;
        //display:none;
      }


      img, object {
        grid-row: 1;
        width: 350px;
        height: 250px;
        object-fit: cover;
        border-radius: 12px 12px 0 0;
      }

      .defaultImage {
        border: 1px solid lightgray;
        background-color: #ead8c2;
        object-fit: contain;
        border-radius: 12px 12px 0 0;
      }


      h1 {
        grid-row: 2;
        margin: 0px;
        padding: 2px 20px;
        color: white;
        font-size: 20px;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        height: 100px;

        &.compact {
          font-size: 16px;
        }
      }
    }

  }

}

@media only screen and (max-width: 700px) {
  .landmark-list {
    flex-direction: column;
  }
}
