.MenuScreen {
  max-width: 800px;
  min-height: 500px;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: space-around;

  margin: 0 auto;

  p {
    display: block;
    line-height: 40px;
  }

  .menu-button-list {
    display: grid;
    grid-template-columns: 300px 300px;
    grid-template-rows: 100px 100px;
    gap: 1px 1px;
    align-items: center;
    justify-items: center;
    grid-gap: 2em;
  }

}

@media only screen and (max-width: 700px) {
  .MenuScreen {
    .menu-button-list {
      grid-template-columns: 300px !important;
      grid-template-rows: 100px 100px 100px 100px !important;
    }
  }
}
